import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import ComingVideoWeb from "../../Assets/Video/VideoHome2.mp4";
import ComingVideoMobile from "../../Assets/Video/VideoHome2.mp4";
import emailjs from "@emailjs/browser";
import { ReactComponent as SubmitMail } from "../../Assets/Svg/submit-mail.svg";
import { ReactComponent as Phone } from "../../Assets/Svg/Time.svg";
import { ReactComponent as MapPin } from "../../Assets/Svg/Location.svg";
import { ReactComponent as DirectionMap } from "../../Assets/Svg/direction.svg";
import { ReactComponent as Date } from "../../Assets/Svg/Date.svg";
import { ReactComponent as SpaceBetween } from "../../Assets/Svg/Arrow-between-element.svg";
import { ReactComponent as SpaceSmall } from "../../Assets/Svg/Arrow-space-small.svg";
import { ReactComponent as Facebook } from "../../Assets/Svg/Facebook.svg";
import { ReactComponent as LinkedInIcon } from "../../Assets/Svg/Linked.svg";
import { ReactComponent as InstagramIcon } from "../../Assets/Svg/Insta.svg";
import NoteBook from "../../Assets/Svg/notebook.svg";
import PhoenixIcon from "../../Assets/Svg/PhoenixIcon.svg";
import CountryCode from "../../Assets/Svg/Country_Code.svg";
import PhoneNumberInput from "../../Assets/Component/country";
import HeroHomeVideo from "../../Assets/Component/Hero";

export default function HeroHome() {
  const form = useRef();
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [isSuccess, setIsSuccess] = useState(false); // Success message state

  // Custom validation function
  const validateInputs = (formData) => {
    const name = formData.get("user_name").trim();
    const email = formData.get("user_email").trim();
    const phone = formData.get("user_phone").trim();
    const company = formData.get("user_company").trim();

    // Check if name is valid (at least 6 characters)
    if (name.length < 6) {
      alert("Please enter at least 6 characters for your name.");
      return false;
    }

    // Check if email is valid (contains '@')
    if (!email.includes("@")) {
      alert("Please enter a valid email address.");
      return false;
    }

    // Check if phone number is exactly 9 digits and numeric
    if (phone.length !== 9 || isNaN(phone)) {
      alert("Please enter a valid 9-digit phone number.");
      return false;
    }

    // Check if company name is valid (at least 3 characters)
    if (company.length < 3) {
      alert("Please enter at least 3 characters for your company name.");
      return false;
    }

    return true;
  };

  // Function to handle form submission
  const sendEmailAfterVerification = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    if (!validateInputs(formData)) return;

    const choicesString = selectedChoices.map((choice) => `ID: ${choice.id}, Label: ${choice.label}`).join("; ");
    formData.append("user_choices", choicesString);

    setIsLoading(true); // Start loading

    try {
      // Send email via EmailJS
      await emailjs.sendForm("service_sp9qj68", "template_v7oz4gc", form.current, "yzQYkOJr2-Fh9CyXR");

      setIsSuccess(true); // Set success state
      setIsLoading(false); // Stop loading
      alert("Thank you! Your email has been sent."); // Success message

      if (form.current) {
        form.current.reset(); // Reset the form after submission
      }
    } catch (error) {
      console.error("EmailJS Error: ", error);
      alert("Failed to send email. Please try again later.");
      setIsLoading(false); // Stop loading on error
    }
  };

  return (
    <>
      {/*  */}
      <HeroHomeVideo />
      {/*  */}
      <div className="HomeS1Main">
        <div className="HomeS1">It’s our pleasure to warmly welcome you to be part of our event.</div>
      </div>
      {/*  */}
      <>
        <div>
          <form ref={form} onSubmit={sendEmailAfterVerification}>
            <div className="contact_part2_second">
              {/* here will be inputs for contact-us */}
              <div className="HomeS2">Enter your details</div>
              <div className="HomeSpaceLine"></div>
              <div className="con_part2_Input1">
                {/* first input for name */}
                <label class="input">
                  <input class="input__field input_1" type="text" placeholder="Name" name="user_name" required />
                  <span class="input__label input_1">Your Name</span>
                </label>

                <div className="con_part2_Input2">
                  <PhoneNumberInput />
                </div>
                {/* second input for Email */}
              </div>
              {/* third input with choose country for phone number */}
              <label class="input">
                <input class="input__field input__fieldFull input_1" type="text" placeholder="E-mail" name="user_email" required />
                <span class="input__label">Email</span>
              </label>
              {/* next 2 input for company and Co founder */}
              <div className="con_part2_Input1">
                {/* first input for name */}
                <label class="input">
                  <input class="input__field input__fieldFull input_1 input_1" type="text" placeholder="Company" name="user_company" required />
                  <span class="input__label input_1">Company</span>
                </label>
                {/* second input for Email */}
              </div>
            </div>
            {/* */}
            <div className="contact_part2_third">
              <div className="contact_part2_third_s1">
                <div className="solutionSubmit">
                  <button className="Contact_submitButton" type="submit">
                    <SubmitMail />
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
      {/*  */}
      <div className="contact_part3">
        <div className="contact_part3_contain">
          {/* part 3 first */}
          <div className="HomeS2Second">Event details</div>
          <div className="HomeSpaceLine"></div>

          <div className="HomeS3">
            <div className="HomeS3P1">
              <img src={NoteBook} alt="" className="HomeS3P1Img" />
              <div className="HomeS3P1T">
                during the event <br /> we will discuss
              </div>
            </div>
            {/*  */}
            <div className="HomeS3P1Main">
              <div className="HomeS3P1">
                <img src={PhoenixIcon} alt="" className="HomeS3P1Img2" /> <div className="HomeS3P1T2">Sap rise (migration and conversion)</div>
              </div>
              {/*  */}
              <div className="HomeS3P1">
                <img src={PhoenixIcon} alt="" className="HomeS3P1Img2" /> <div className="HomeS3P1T2">SAP HR (SuccessFactors)</div>
              </div>
              {/*  */}
              <div className="HomeS3P1">
                <img src={PhoenixIcon} alt="" className="HomeS3P1Img2" /> <div className="HomeS3P1T2">sap Real Estate AI pricing tool</div>
              </div>
            </div>
            {/*  */}
          </div>
          {/* part 3 second */}
          <div className="contact_part3_second">
            {/* part 3 info 1 */}
            <div className="con_part3_second_info FirstOneInfo">
              <div className="Con_Info_P">
                <Phone className="con_info_icon" />
                <p className="part3_second_info1_p_bold">Event Date</p>
              </div>
              <div className="contact_info1">
                <p className="part3_second_info1_p">11 December 2024</p>
              </div>
            </div>
            {/* end info 1 */}
            <SpaceBetween className="SpaceBetweenMobile" />
            {/* part 3 info 2 */}
            <div className="con_part3_second_info">
              <div className="Con_Info_P">
                <MapPin className="con_info_icon_Second" />
                <p className="part3_second_info1_p_bold">Event location</p>
              </div>

              <div className="contact_info1">
                <p className="part3_second_info1_p">
                  Fairmont the Palm, <br /> Dubai, UAE
                </p>
                <div className="part3_second_info_liveMap">
                  <a href="https://maps.app.goo.gl/bjcvcbvwUeZNvZmcA" target="blank">
                    <DirectionMap />
                    View on google maps
                  </a>
                </div>
              </div>
            </div>
            {/* end info 2 */}
            <SpaceBetween className="SpaceBetweenMobile" />
            {/* part 3 info 3 */}
            <div className="con_part3_second_info con_part3_second_infoLast">
              <div className="Con_Info_P">
                <Date className="con_info_icon_Second" />
                <p className="part3_second_info1_p_bold">Event Duration</p>
              </div>
              <p className="part3_second_info1_p">
                {" "}
                From 9:00 AM <br /> To 5:00 PM
              </p>
            </div>
          </div>
          <div className="HomeS2Second LastOne">Keep In touch</div>
          <div className="HomeSpaceLine PadTop"></div>
        </div>
      </div>

      <div className="contact_part3_third">
        <div className="contact_part3_third_icon">
          <a href="https://www.linkedin.com/company/phoenix-consulting-egy/" target="blank" className="contact_part3_third_icon_A">
            <div className="contact_part3_social">
              <LinkedInIcon className="con_icon" />
              Linked In
            </div>
          </a>
          <SpaceSmall className="SpaceSmall" />
          <a href="https://www.facebook.com/phoenixconsultingmea" target="blank" className="contact_part3_third_icon_A">
            <div className="contact_part3_social">
              <Facebook className="con_icon" />
              Facebook
            </div>
          </a>
          <SpaceSmall className="SpaceSmall" />
          <a href="https://www.instagram.com/phoenixconsultingmea/?igshid=MzRlODBiNWFlZA%3D%3D" target="blank" className="contact_part3_third_icon_A">
            <div className="contact_part3_social">
              <InstagramIcon className="con_icon" />
              Instagram
            </div>
          </a>
        </div>
      </div>
    </>
  );
}
